
































































































































































































































import Vue from "vue";
import { InterviewRoundStatus } from "@/interfaces/recruiter/hiring_manager/interview_status";
import { mapActions, mapMutations } from "vuex";
import {
  ACTIVATE_PUBLISHED_JOB,
  UPDATE_MATCHING_CANDIDATE_STATUS,
  CANDIDATE_FILTER,
  GET_CHECKLIST_REQUIRED_DOCUMENT
} from "@/store/modules/recruiter/constants";
import {
  MatchingCandidatesStatus,
  RequiredDocumentChecklist
} from "@/store/modules/recruiter/interfaces";
import { ROOT_ERROR } from "@/store/modules/root/constants";
import DocumentChecklistComponent from "./DocumentChecklistComponent.vue";

export default Vue.extend({
  name: "OnBoardingDetail.vue",
  components: { DocumentChecklistComponent },
  data() {
    return {
      is_hired_manager: false,
      hr_director_approval: false,
      candidate_id: 0,
      job_id: 0,
      loading: false,
      valid: false,
      required_rule: [(value: string) => !!value || "Field is required"],
      job_decription: true,
      rejected_loading: false,
      document_dialog: false,
      checklist_documents: [] as RequiredDocumentChecklist[],
      document_loading: true
    };
  },
  async mounted() {
    this.candidate_id = parseInt(sessionStorage.getItem("user") ?? "");
    this.job_id = parseInt(sessionStorage.getItem("job") ?? "");
    await this.init();
  },
  computed: {
    get_document_count(): number {
      return this.checklist_documents.reduce((acc, doc) => {
        return doc.status === "uploaded" ? acc + 1 : acc;
      }, 0);
    }
  },
  methods: {
    ...mapMutations({
      root_error: ROOT_ERROR
    }),
    ...mapActions("recruiter", {
      activate_published_job: ACTIVATE_PUBLISHED_JOB,
      update_matching_candidate_status: UPDATE_MATCHING_CANDIDATE_STATUS,
      get_required_document: GET_CHECKLIST_REQUIRED_DOCUMENT
    }),
    ...mapMutations("recruiter", {
      set_candidate_filter: CANDIDATE_FILTER
    }),
    get_status_color(status: string) {
      if (status === InterviewRoundStatus.PASSED) {
        return "green";
      } else {
        return "blue";
      }
    },
    get_status_text(status: string) {
      if (status === InterviewRoundStatus.PASSED) {
        return "Passed";
      } else {
        return "In Progress";
      }
    },
    async onboard_candidate() {
      if (
        !this.checklist_documents.every((item) => item.status === "uploaded")
      ) {
        return this.root_error("Please Upload all the Attachments to continue");
      }
      if (!this.is_hired_manager || !this.hr_director_approval) {
        return this.root_error(
          "Please approve or reject the candidates first to continue"
        );
      }
      this.loading = true;
      if (this.job_id) {
        const resp = await this.activate_published_job(this.job_id);
        if (resp) {
          const status = {
            job_id: this.job_id,
            candidate_id: this.candidate_id,
            status: MatchingCandidatesStatus.JOINED
          };
          const res = await this.update_matching_candidate_status(status);
          if (res) {
            this.set_candidate_filter(MatchingCandidatesStatus.JOINED);
            this.$router.push("/recruiter/candidates");
          }
        }
      } else {
        this.root_error("Job id not found");
      }
      this.loading = false;
    },
    async reject_candidate() {
      this.rejected_loading = true;
      const status = {
        job_id: this.job_id,
        candidate_id: this.candidate_id,
        status: MatchingCandidatesStatus.REJECTED
      };
      const res = await this.update_matching_candidate_status(status);
      if (res) {
        this.set_candidate_filter(MatchingCandidatesStatus.REJECTED);
        this.$router.push("/recruiter/candidates");
      }
      this.rejected_loading = false;
    },
    view_attachments() {
      if (this.checklist_documents.length > 0) {
        this.document_dialog = true;
      } else this.root_error("No Attachments Found");
    },
    async init() {
      this.document_loading = true;
      const checklist_document = await this.get_required_document(this.job_id);
      if (checklist_document) {
        this.checklist_documents = checklist_document.reverse();
      } else {
        this.root_error("Error fetching checklist document");
      }
      this.document_loading = false;
    }
  }
});
