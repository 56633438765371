










































































































import {
  DELETE_CHECKLIST_REQUIRED_DOCUMENT,
  UPLOAD_CHECKLIST_REQUIRED_DOCUMENT
} from "@/store/modules/recruiter/constants";
import { RequiredDocumentChecklist } from "@/store/modules/recruiter/interfaces";
import { ROOT_ERROR, ROOT_NOTIFICATION } from "@/store/modules/root/constants";
import Vue from "vue";
import { mapActions, mapMutations } from "vuex";

export default Vue.extend({
  name: "DocumentChecklistComponent",
  components: {},
  data() {
    return {
      dialog: true,
      resumes: [] as File[],
      file_size: 1048576,
      checklist_id: 0
    };
  },
  props: {
    candidate_id: {
      type: Number
    },
    job_id: {
      type: Number
    },
    checklist_documents: {
      type: Array as () => RequiredDocumentChecklist[]
    },
    document_loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    get_document_count(): number {
      return this.checklist_documents.reduce((acc, doc) => {
        return doc.status === "uploaded" ? acc + 1 : acc;
      }, 0);
    }
  },
  methods: {
    ...mapMutations({
      root_error: ROOT_ERROR,
      root_notification: ROOT_NOTIFICATION
    }),
    ...mapActions("recruiter", {
      upload_checklist_document: UPLOAD_CHECKLIST_REQUIRED_DOCUMENT,
      delete_required_document: DELETE_CHECKLIST_REQUIRED_DOCUMENT
    }),
    file_input(index: number) {
      const file_uploader = this.$refs.fileInput as any;
      file_uploader[index].$refs.input.click();
      this.checklist_id = this.checklist_documents[index].id;
    },
    async process_selected_resume(file: File) {
      this.resumes.push(file);
      this.validate_resume_type_and_size();
      await this.upload_document();
    },
    validate_resume_type_and_size() {
      if (this.resumes && this.resumes.length) {
        this.resumes = this.resumes.filter((file: File) => {
          return (
            (file.size <= this.file_size && file.type === "text/plain") ||
            file.type === "application/pdf" ||
            file.type ===
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
            file.type === "application/doc" ||
            file.type === "application/ms-doc" ||
            file.type === "application/msword"
          );
        });
      } else {
        this.root_error("Please Upload a valid file");
        return false;
      }
    },
    async upload_document() {
      if (this.resumes && this.resumes.length) {
        this.$emit("loading");
        const formData = new FormData();
        formData.append("checklist_doc", this.resumes[0]);
        formData.append("checklist_id", this.checklist_id.toString());
        const result = await this.upload_checklist_document(formData);
        if (result) {
          this.root_notification("Document Uploaded Successfully");
          this.$emit("update");
        } else {
          this.$emit("loaded");
          this.root_error("Please Upload a valid file");
        }
      } else {
        this.root_error("Please Upload a valid file");
      }
    },
    async delete_document(checklist_id: number) {
      if (checklist_id) {
        const result = await this.delete_required_document(checklist_id);
        if (result) {
          this.root_notification("Document Deleted Successfully");
          this.$emit("update");
        } else this.root_error("Error deleting document");
      }
    }
  }
});
