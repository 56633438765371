var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"view-candidate-profile"},[_c('GoBackHeader',{staticClass:"mb-8",attrs:{"title":"Shortlisted Candidate"}}),(!_vm.loading)?[(_vm.candidate_filter === _vm.CandidateStatusOption.REJECTED)?_c('section',[_c('RejectedDetail')],1):_vm._e(),_c('section',{staticClass:"profile-content mt-6"},[_c('div',{staticClass:"profile-content-body d-flex flex-column"},[_c('div',{staticClass:"profile-content-user-details base-card pa-0"},[_c('div',{staticClass:"px-10 py-4 user-details d-flex flex-column flex-md-row"},[_c('div',[_c('div',{staticClass:"d-flex align-center mt-4"},[_c('ProfilePicture',{staticClass:"mr-4",attrs:{"user":_vm.data.user}}),_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex align-center"},[(
                        _vm.data.profile.profile &&
                        _vm.data.profile.profile.personal_details.name
                      )?_c('p',{staticClass:"mb-0 base-section-title user-name"},[(
                          _vm.data.profile.profile.personal_details.name
                            .first_name
                        )?_c('span',[_vm._v(" "+_vm._s(_vm.data.profile.profile.personal_details.name .first_name)+" ")]):_vm._e(),(
                          _vm.data.profile.profile.personal_details.name
                            .middle_name
                        )?_c('span',[_vm._v(" "+_vm._s(_vm.data.profile.profile.personal_details.name .middle_name)+" ")]):_vm._e(),(
                          _vm.data.profile.profile.personal_details.name.last_name
                        )?_c('span',[_vm._v(" "+_vm._s(_vm.data.profile.profile.personal_details.name.last_name)+" ")]):_vm._e()]):_c('UserName',{attrs:{"user":_vm.data.user}}),_c('UserRating',{staticClass:"ml-2"}),(
                        _vm.candidate_filter === _vm.CandidateStatusOption.REJECTED
                      )?_c('v-btn',{staticClass:"rejected text-capitalize",attrs:{"text":"","color":"danger"}},[_c('inline-svg',{staticClass:"mr-2",attrs:{"src":require("@/assets/icons/linear/close-circle.svg")}}),_vm._v(" Rejected ")],1):_vm._e()],1),_c('UserDesignation',{attrs:{"user_profile":_vm.data.profile.profile ? _vm.data.profile.profile : null}})],1)],1),_c('div',{staticClass:"mt-8 user-details-extra"},[_c('div',{staticClass:"details"},[(false)?[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-skeleton-loader',{attrs:{"type":"image","width":"100px","height":"20px"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-skeleton-loader',{attrs:{"type":"image","width":"100px","height":"20px"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-skeleton-loader',{attrs:{"type":"image","width":"100px","height":"20px"}})],1)],1)]:_vm._e(),[_c('SkillMatch',{attrs:{"matched_score":0,"error":true}}),_c('SkillGap',{attrs:{"matched_score":0,"error":true}}),_c('UserExperience',{attrs:{"profile":_vm.data.profile}})]],2)])]),_c('div',{staticClass:"\n                mt-8 mt-md-0\n                ms-md-auto\n                d-flex\n                align-center\n                flex-column\n                justify-space-between\n              "},[_c('div',{staticClass:"d-flex align-center mb-2"},[_vm._l((_vm.data.user.social_links),function(social_account,index){return [_c('v-btn',{key:index,style:(!social_account.url ? 'opacity: 0.6' : ''),attrs:{"href":!social_account.url.startsWith('http')
                        ? 'https://' + social_account.url
                        : social_account.url,"disabled":!social_account.url,"target":"_blank","icon":"","large":"","color":"primary"}},[_c('p',[_vm._v(_vm._s(social_account.link))]),_c('img',{staticClass:"user-details-social-icon",attrs:{"alt":social_account.platform,"src":_vm.social_icons[social_account.platform]}})])]})],2),(_vm.data.user.resume_uri)?_c('v-btn',{staticClass:"my-4 base-submit-button user-details-btn details-btn",attrs:{"href":_vm.data.user.resume_uri,"download":""}},[_c('inline-svg',{staticClass:"mx-2",attrs:{"transformSource":_vm.transform_resume_icon,"src":require('@/assets/icons/bold/document-text.svg')}}),_vm._v(" "+_vm._s(!_vm.data.user.resume_uri ? "No Resume Found" : _vm.$t("shared.resume"))+" ")],1):_vm._e()],1)]),_c('InterviewAndJobDetails',{attrs:{"detail_payload":_vm.job_detail_payload}})],1),(_vm.candidate_filter === _vm.CandidateStatusOption.SHORTLISTED)?_c('div',[_c('InterviewRounds')],1):(_vm.candidate_filter === _vm.CandidateStatusOption.INTERVIEW)?_c('div',[_c('InterviewRoundResult')],1):(_vm.candidate_filter === _vm.CandidateStatusOption.ONBOARDING)?_c('div',[_c('OnBoardingDetail')],1):_vm._e()])])]:[_c('UserProfileLoader')]],2)}
var staticRenderFns = []

export { render, staticRenderFns }