





















import Vue from "vue";

export default Vue.extend({
  name: "InterviewAndJobDetails",
  components: {},
  data() {
    return {
      selected_round: 1,
      interview_detail: [[{ label: "email@hcms.ai", value: "" }]],
      start_date: "",
      dropdown_icon: require("@/assets/icons/linear/direction-down.svg")
    };
  },
  props: {
    detail_payload: {
      type: Array,
      required: true
    }
  },
  methods: {}
});
