










































































import Vue from "vue";
import {
  InterviewRound,
  InterviewRoundStatus
} from "@/interfaces/recruiter/hiring_manager/interview_status";
import { mapActions, mapMutations } from "vuex";
import {
  CANDIDATE_FILTER,
  INTERVIEW_LISTING,
  UPDATE_INTERVIEW_STATUS,
  UPDATE_MATCHING_CANDIDATE_STATUS
} from "@/store/modules/recruiter/constants";
import {
  MatchingCandidatesStatus,
  UpdateInterviewStatusPayload
} from "@/store/modules/recruiter/interfaces";

export default Vue.extend({
  name: "InterviewRoundResult",
  components: {},
  data() {
    return {
      interview_rounds: [] as InterviewRound[],
      candidate_id: 0,
      job_id: 0,
      loading: false,
      required_rule: [(value: string) => !!value || "Field is required"],
      valid: false,
      all_status: [
        { text: "In Progress", value: InterviewRoundStatus.IN_PROGRESS },
        { text: "Passed", value: InterviewRoundStatus.PASSED },
        { text: "Failed", value: InterviewRoundStatus.FAILED }
      ]
    };
  },
  mounted() {
    this.candidate_id = parseInt(sessionStorage.getItem("user") ?? "");
    this.job_id = parseInt(sessionStorage.getItem("job") ?? "");
    this.fetch_interview_result();
  },
  watch: {
    interview_rounds: {
      deep: true,
      handler(value: InterviewRound[]) {
        this.valid = value.every(
          (item: InterviewRound) =>
            item.status === InterviewRoundStatus.PASSED ||
            item.status === InterviewRoundStatus.FAILED
        );
      }
    }
  },
  methods: {
    ...mapActions("recruiter", {
      fetch_interview_listing: INTERVIEW_LISTING,
      update_matching_candidate_status: UPDATE_MATCHING_CANDIDATE_STATUS,
      update_interview_status: UPDATE_INTERVIEW_STATUS
    }),
    ...mapMutations("recruiter", {
      set_candidate_filter: CANDIDATE_FILTER
    }),
    get_status_color(status: string) {
      if (status === InterviewRoundStatus.PASSED) {
        return "blue";
      } else if (status === InterviewRoundStatus.FAILED) {
        return "red";
      } else if (status === InterviewRoundStatus.IN_PROGRESS) {
        return "green";
      }
    },
    get_status_text(status: string) {
      if (status === InterviewRoundStatus.PASSED) {
        return "Passed";
      } else if (status === InterviewRoundStatus.FAILED) {
        return "Failed";
      } else if (status === InterviewRoundStatus.IN_PROGRESS) {
        return "InProgress";
      }
    },
    async fetch_interview_result() {
      const result = await this.fetch_interview_listing({
        candidate_id: this.candidate_id,
        job_id: this.job_id
      });
      if (result) {
        this.interview_rounds = result.map((item: any) => {
          return {
            emails: item.extras.conducted_by,
            status:
              item.interview_status === "invite_sent"
                ? InterviewRoundStatus.IN_PROGRESS
                : item.interview_status,
            interview_id: item.id
          };
        });
        this.valid = this.interview_rounds.every(
          (item: any) =>
            item.status === InterviewRoundStatus.PASSED ||
            item.status === InterviewRoundStatus.FAILED
        );
      }
    },
    async onboard_candidate() {
      this.loading = true;
      const status = {
        job_id: this.job_id,
        candidate_id: this.candidate_id,
        status: ""
      };
      if (this.interview_rounds.length > 0) {
        const is_passed = this.interview_rounds.every(
          (item) => item.status === InterviewRoundStatus.PASSED
        );

        if (is_passed) {
          status.status = MatchingCandidatesStatus.ONBOARD;
        } else status.status = MatchingCandidatesStatus.REJECTED;
        const res = await this.update_matching_candidate_status(status);
        if (res) {
          if (is_passed)
            this.set_candidate_filter(MatchingCandidatesStatus.ONBOARD);
          else this.set_candidate_filter(MatchingCandidatesStatus.REJECTED);
          this.$router.push("/recruiter/candidates");
        }
      }
      this.loading = false;
    },
    async change_status(
      value: InterviewRoundStatus,
      index: number,
      interview_id: number
    ) {
      if (
        [InterviewRoundStatus.PASSED, InterviewRoundStatus.FAILED].includes(
          value
        )
      ) {
        const payload = {
          interview_id,
          action: value,
          candidate_id: this.candidate_id
        } as UpdateInterviewStatusPayload;

        const res = await this.update_interview_status(payload);
        if (res) this.interview_rounds[index].status = value;
      }
    }
  }
});
