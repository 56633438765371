export enum InterviewRoundStatus {
  PASSED = "PASSED",
  FAILED = "FAILED",
  IN_PROGRESS = "IN_PROGRESS"
}

export interface InterviewRound {
  emails: string[];
  status: InterviewRoundStatus;
  interview_id: number;
}
