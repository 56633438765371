














import Vue from "vue";

export default Vue.extend({
  name: "RejectedDetail",
  data() {
    return {
      loading: false
    };
  }
});
