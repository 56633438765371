












































































































import Vue from "vue";
import DatePicker from "@/components/shared/view_profile/DatePicker.vue";
import { mapActions, mapMutations } from "vuex";
import {
  CANDIDATE_FILTER,
  UPDATE_INTERVIEW_SCHEDULE,
  UPDATE_MATCHING_CANDIDATE_STATUS
} from "@/store/modules/recruiter/constants";
import moment from "moment";
import {
  InterviewSchedulePayload,
  MatchingCandidatesStatus,
  SelectedDates
} from "@/store/modules/recruiter/interfaces";
import { ROOT_ERROR } from "@/store/modules/root/constants";

export default Vue.extend({
  name: "InterviewRounds",
  components: {
    DatePicker
  },
  data() {
    return {
      selected_round: 1,
      interview_detail: [[{ label: "email@corehcms.ai", value: "" }]],
      dropdown_icon: require("@/assets/icons/linear/direction-down.svg"),
      required_rule: [(value: string) => !!value || "Field is required"],
      no_previous_dates_allowed: [
        (value: string) => {
          return value && moment(value).isSameOrAfter(moment(), "day")
            ? true
            : "Please select a future date";
        }
      ],
      selected_dates: {} as SelectedDates,
      candidate_id: 0,
      job_id: 0,
      valid: false,
      loading: false
    };
  },
  mounted() {
    this.candidate_id = parseInt(sessionStorage.getItem("user") ?? "");
    this.job_id = parseInt(sessionStorage.getItem("job") ?? "");
  },
  methods: {
    ...mapActions("recruiter", {
      set_interview_round: UPDATE_INTERVIEW_SCHEDULE,
      update_matching_candidate_status: UPDATE_MATCHING_CANDIDATE_STATUS
    }),
    ...mapMutations({
      root_error: ROOT_ERROR
    }),
    ...mapMutations("recruiter", {
      set_candidate_filter: CANDIDATE_FILTER
    }),
    addTextField(index: number) {
      this.interview_detail[index].push({ label: "email@hcms.ai", value: "" });
    },
    set_interview_rounds(index: number) {
      const current_length = this.interview_detail.length;
      if (index > current_length) {
        for (let i = current_length; i < index; i++) {
          this.interview_detail.push([{ label: "email@hcms.ai", value: "" }]);
        }
      } else if (index < current_length) {
        this.interview_detail.splice(index, current_length - index);
      }
    },
    select_start_date(date: string, index: number) {
      this.selected_dates[index] = date;
    },
    async interview_schedule() {
      this.loading = true;
      let payload = {
        candidate_id: this.candidate_id,
        job_id: this.job_id,
        rounds: null
      } as InterviewSchedulePayload;
      if (this.candidate_id && this.job_id) {
        payload.rounds = this.interview_detail.map((round, index) => {
          return {
            schedule_date: this.selected_dates[index],
            conducted_by: round.map((member) => member.value)
          };
        });
        const result = await this.set_interview_round(payload);
        if (result) {
          const status = {
            job_id: this.job_id,
            candidate_id: this.candidate_id,
            status: MatchingCandidatesStatus.INTERVIEWED
          };
          const res = await this.update_matching_candidate_status(status);
          if (res) {
            this.set_candidate_filter(MatchingCandidatesStatus.INTERVIEWED);
            this.$router.push("/recruiter/candidates");
          }
        }
      } else this.root_error("Error in scheduling interview, Try Again later");
      this.loading = false;
    }
  }
});
